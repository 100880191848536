body {
    font-family: synthese, sans-serif;
}

h1,
h2,
h3,
h4 {
    font-family: synthese, sans-serif;
    font-weight: 700;
    letter-spacing: 0.07em;
}

.campaign {
    font-family: campaign, sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.07em;
}

.track-title {
    font-weight: 700;
    color: #000;
    text-transform: capitalize;
    font-size: 12px;
}

.track-artist {
    font-weight: 200;
    color: #000;
    text-transform: capitalize;
    font-size: 12px;
}

.text-albuminfo {
    font-weight: 200;
    color: #000;
    /* text-transform: capitalize; */
    font-size: 12px;
}

.text-albuminfo-data {
    font-weight: 600;
    color: #000;
    text-transform: capitalize;
    font-size: 12px;
    padding-left: 5px;
}

.albumInfo-img {
    max-height: 40vw;
    max-width: 40vw;
    width: 300px;
    height: 300px;
}

.font-bold {
    font-weight: 700;
}


.text-description {
    font-weight: 400;
    font-size: 12px;
    color: #000;
    display: block;
}

.desc-style {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: inherit;
}

.text-title {
    font-weight: 400;
    font-size: 12px;
}

.addbutton {
    width: 35px;
    height: 35px;
}

button.flex,
.greenbuttonwide>button {
    border: none;
    padding: 0;
}

.pinkbutton {
    height: 40px;
    background-image: url('../assets/PinkButton.svg');
    background-repeat: none;
    width: 120px;
    color: #FFFFFF;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
}

.greenbutton {
    height: 40px;
    background-image: url('../assets/GreenButton.svg');
    background-repeat: none;
    width: 120px;
    color: #000000;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
}

.button {
    height: 40px;
    background-color: #dedede;
    border-radius: 25px;
    background-repeat: none;
    /* width: auto; */
    color: #000000;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
}

.pinkbuttonwide {
    height: 40px;
    background-image: url('../assets/PinkButtonWide.svg');
    background-repeat: no-repeat;
    background-size: 100% 40px;
    width: 160px;
    color: #FFFFFF;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    border: 0;
    padding: 0;
}


.greenbuttonwide {
    height: 40px;
    background-image: url('../assets/GreenButtonWide.svg');
    background-repeat: no-repeat;
    background-size: 100% 40px;
    width: 160px;
    color: #000000;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    border: 0;
    padding: 0;
}

.navPinkbuttonwide {
    height: 40px;
    background-image: url('../assets/PinkButtonWide.svg');
    background-repeat: no-repeat;
    background-size: 100% 40px;
    width: 111px;
    color: #FFFFFF;
    line-height: 36px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    border: 0;
    padding: 0;
}

.greenbutton button,
.greenbutton a {
    color: #000000;
    font-weight: bold;
    border: none;
    padding: 0;
}

.pinkbutton button,
.pinkbutton a {
    color: #FFFFFF;
    font-weight: bold;
    border: none;
    padding: 0;
}

form .MuiOutlinedInput-notchedOutline,
.MuiOutlinedInput-notchedOutline,
.pinkform,
.borderpink {
    border: 2px solid #E30185 !important;
    border-radius: 25px !important;
    color: #000;
}

.pinkblock {
    background-color: #E30185;
    color: #FFF;
    padding: 10px;
    margin: 10px 10px 10px 0px;
    width: auto;
    /* text-align: center; */
}

.syncpink {
    background-color: #E30185;
}

.syncpinkbutton {
    background-color: #E30185;
    border-radius: 25px;
}

.syncbuttonlight {
    background-color: #E30185;
    border-radius: 25px;
    margin: 5px;
}

.filterplus {
    background-image: url('../assets/BlackButtonRoundPlusSmall.svg');
    background-position: left;
    background-repeat: no-repeat;
    background-size: 15px;
}

.filterminus {
    background-image: url('../assets/BlackButtonRoundMinusSmall.svg');
    background-position: right;
    background-repeat: no-repeat;
    background-size: 15px;
}

.filterselected {
    background-image: none;
    background-position: right;
    background-repeat: no-repeat;
    background-size: 15px;
    color: black;
    background-color: lightgrey;
    border-radius: 15px;
}

.filterclose {
    height: 15px;
    width: 15px;
    margin-right: -15px;
}

.greenbg {
    background-color: #3AEDA4;
}

.stepsbox {
    margin-top: 20px;
}

.track-title {
    font-weight: 700;
    color: #000;
    text-transform: capitalize;
}

.newprojects {
    text-transform: capitalize;
}

.mobile-filter-wrapper .MuiSlider-markLabel {
    color: white;
}

.break-word {
    word-break: break-all;
}

.center-align {
    align-items: center;
    display: flex;
  }

.row-line {
    display: flex;
    justify-content: space-around;
}
